import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoLinkSvg,
  InstagramSvg,
  LinkedInSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionAboutBaseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  NeuroFitInstagramLink,
  NeuroFitLinkedInLink,
  recordLinkClickAndRedirect,
  BlogSomaticExercisesWeightLossUrl,
  BlogSomaticExercisesTraumaUrl,
  BlogSomaticExercisesSleepUrl,
  BlogSomaticExercisesAngerUrl,
  BlogDorsalVagalRegulationUrl,
  BlogNervousSystemRegulationTimeUrl,
  BlogNervousSystemCoachUrl,
  BlogSomaticCoachUrl,
  LearnOverstimulatedUrl,
  LearnSomaticYogaUrl,
  LearnFunctionalFreezeUrl,
  LearnCoreSleepUrl,
  LearnBrainMuscleUrl,
  LearnIGAuditBridgeUrl,
  ProductionLegalTermsOfServiceUrl,
  ProductionLegalPrivacyPolicyUrl,
  getCurrentYear,
} from '../utils'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
} from '../constants/gaEventLabels'

import QrCodeDownloadModal from './modals/QrCodeDownloadModal'
import DownloadButtonsSection from './common/DownloadButtonsSection'
import WebsiteFooterSection from './common/WebsiteFooterSection'
import Helmet from './common/Helmet'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class AboutPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
        showQrCodeModal: false
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showQrCodeModal, showIframe} = this.state

    const currentYear = getCurrentYear()

    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"About NEUROFIT | Nervous System + Somatics App"}
          description={"NEUROFIT creates industry-leading nervous system + somatics tech for busy professionals, therapists, doctors, and organizations."}
          href={ProductionAboutBaseUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
            <NeuroFitNamedLogoLinkSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              href="/"
            />
            {!isNative && (
              <div style={{display: "flex", alignItems: "center"}}>
                <a
                  href={"/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                >
                  {"Home"}
                </a>
              </div>
            )}
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw", minHeight: innerHeight}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", margin: "calc(60px + 3vh) 0px"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <h1 style={{fontSize: "min(8.5vw, 40px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"About NEUROFIT"}
                  </h1>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Built From Personal Experience"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"There’s nothing wrong with you - you just have a dysregulated nervous system. This simple truth led us, Loren and Andrew Hogue, on a mission to change how the world manages stress and burnout."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Loren's journey began after the sudden passing of her father in 2011. As a newly graduated lawyer, she faced anxiety, sleep disturbances, digestive issues, and the emotional rollercoaster of grief. Andrew grew up in a highly neurodiverse home, resulting in Complex PTSD throughout his childhood + early adult life."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Our paths crossed in 2019, and three years later, we combined our personal experiences with Loren's 10 years of somatic+business coaching and Andrew's 10 years of tech+product expertise to create NEUROFIT."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"End Chronic Stress + Burnout"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"NEUROFIT is here to solve the global chronic stress and burnout crisis. Chronic stress changes the body’s physiology, which leads to a host of mental and physical health issues that we now call burnout."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"We envision a world where nervous system regulation is as easy and accessible as going to the gym."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Today, 100,000+ people in 100+ countries have used NEUROFIT, with 2,000 therapists, doctors, coaches and more using our technology, with features in Forbes, Business Insider, Vogue, Well+Good, SHAPE, Prevention, Men's Health, Real Simple, Medical News Today and more."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"The NEUROFIT APP"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Our flagship product, the NEUROFIT app, is a data-driven, neurosciencescience-based, and holistic tool designed to help you regulate, balance, and optimize your nervous system - fast."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Our aim with this product is to be the last mental + physical health app you'll ever need."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "2vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Experience the benefits in just 5 minutes a day:"}
                  </div>
                  <h3 style={{margin: "3vh 0px 0px 0px", marginInlineStart: 20, fontSize: "min(6vw, 26px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Daily Check-Ins"}
                  </h3>
                  <div style={{fontFamily: "Futura Book", marginInlineStart: 20, marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Check in each morning to strengthen your mind-body connection, measure growth, nurture positive habits, and tailor your coaching."}
                  </div>
                  <h3 style={{margin: "3vh 0px 0px 0px", marginInlineStart: 20, fontSize: "min(6vw, 26px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Built-In HRV Measurement"}
                  </h3>
                  <div style={{fontFamily: "Futura Book", marginInlineStart: 20, marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Measure your daily progress and nervous system recovery with 60-second readings — no wearable needed."}
                  </div>
                  <h3 style={{margin: "3vh 0px 0px 0px", marginInlineStart: 20, fontSize: "min(6vw, 26px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"BALANCE Exercises"}
                  </h3>
                  <div style={{fontFamily: "Futura Book", marginInlineStart: 20, marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Beat stress in 5 minutes. Exercises are tailored for performance based on your in-app nervous system profile."}
                  </div>
                  <h3 style={{margin: "3vh 0px 0px 0px", marginInlineStart: 20, fontSize: "min(6vw, 26px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"CLEAR AI Coach"}
                  </h3>
                  <div style={{fontFamily: "Futura Book", marginInlineStart: 20, marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Clear your mind and emotions anytime with unlimited coaching sessions. Conversations are secure, private, and deleted once finished."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Coach Certification"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Join 500+ health professionals and become a certified nervous system + somatics coach in just 3 weeks. Designed to help any health+wellness professional accelerate client results and grow their business."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"NEUROFIT for Teams"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"We also offer solutions for organizations to combat team burnout and improve performance. With our app, live virtual workshops, and burnout certification training, we transform workplaces to quickly become healthier and more balanced."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Why"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"We believe that wellness can be profoundly simple, and that human body innately knows how to take care of itself when it’s receiving the right inputs. Our tech is designed to help you tune into that knowingness."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"On average, active NEUROFIT members report 54% less stress after just one week. Our app has helped thousands find peace, balance, and clarity in their lives, and now we want to share this gift with the world."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Values"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"At NEUROFIT, we value simplicity, clarity, and performance. Our technology reflects this commitment, making nervous system regulation accessible to everyone. We aim to lead the wellness movement being the single fastest way to enhance daily life and ultimately, the human experience."}
                  </div>
                  <h2 style={{marginTop: "5vh", fontSize: "min(6.5vw, 30px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura"}}>
                    {"Join Us"}
                  </h2>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Whether you’re a busy professional looking to reduce stress, a health professional wanting to add data-driven tech-powered methodology to your practice, or an organization aiming to improve team well-being, we're here for you."}
                  </div>
                  <div style={{fontFamily: "Futura Book", marginTop: "0.5vh", fontSize: "min(5.5vw, 22px)"}}>
                    {"Experience the power of a balanced nervous system. Welcome to NEUROFIT."}
                  </div>
                  {(isIOSDevice || isAndroidDevice) ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                        onClick={() => {
                          recordLinkClickGA("Universal App Download Link")
                          window.open(NeuroFitUniversalAppStoreLink, "_blank", "noopener noreferrer nofollow")
                        }}
                      >
                        {isIOSDevice ? (
                          <AppleSvg />
                        ) : (isAndroidDevice ? (
                          <AndroidSvg />
                        ) : null)}
                        <span>
                          {"DOWNLOAD NOW"}
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitAppleAppStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AppleSvg />
                          <span>
                            {"DOWNLOAD IOS"}
                          </span>
                        </button>
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AndroidSvg />
                          <span>
                            {"DOWNLOAD ANDROID"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <WebsiteFooterSection
              safeAreaInsets={safeAreaInsets}
              onOpenAppQrCodeModal={() => this.setState({showQrCodeModal: true})}
              showFoundersSection
              showWebAppCheckoutSection
              showRecentReleasesSection
            />
          </div>
          <QrCodeDownloadModal
            show={showQrCodeModal}
            onHide={() => this.setState({showQrCodeModal: false})}
            safeAreaInsets={safeAreaInsets}
          />
        </div>
      </div>
    )
  }
}

export default AboutPage;