import { Preferences } from '@capacitor/preferences';

const EXPERIMENTS_PREFERENCES_KEY = "experiments-v4"

// Native
export const MinDayOnePricingAccountCreationTimestamp = 1687528800 // 6-24-2023 00:00:00 AEST

export const EXPERIMENT_FLAG_PAYWALL_GENDER_SPECIFIC = "ef_paywall_gs"
export const EXPERIMENT_FLAG_PAYWALL_PROGRAM_GUARANTEE = "ef_paywall_guarantee"

const ExperimentFlagKeys = [
  EXPERIMENT_FLAG_PAYWALL_GENDER_SPECIFIC,
  EXPERIMENT_FLAG_PAYWALL_PROGRAM_GUARANTEE,
]

// Web

export const EXPERIMENT_FLAGS_WEB_APP_SUBSCRIPTION_PRICING = "experiment_flags_web__app_subscription_pricing_3599"


const WebExperimentFlagKeys = [
  EXPERIMENT_FLAGS_WEB_APP_SUBSCRIPTION_PRICING,
]

const DEFAULT_EXPERIMENTS_ENABLED_FLAG = "experiments_enabled"
const EXPERIMENT_CURRENT_VERSION_FLAG = "experiments_current_version"
const EXPERIMENT_CURRENT_VERSION_VALUE = 7




const generateExperimentFlagValue = (key) => {
  const defaultExperimentFraction = 0.5
  const customExperimentFlagKeyFractions = {
    // []: 0.5
  }
  const customExperimentFraction = customExperimentFlagKeyFractions[key]
  return Math.random() <= ((!!customExperimentFraction) ? customExperimentFraction : defaultExperimentFraction)
}

export const loadUserExperimentFlags = async (isWeb=false) => {
  // Retrieve Current Experiment Preferences.
  const { value } = await getExperimentPreferences(isWeb)
  let experimentFlagsObject;
  try {
    experimentFlagsObject = JSON.parse(value)
  } catch (e) {
    console.log(`Initial Response: ${value}`)
    console.log(`Error Parsing Experiment Flags Response: ${e}`)
  }

  let experimentFlags = experimentFlagsObject || {}

  const configuredFlags = new Set(Object.keys(experimentFlags))

  // Set New Experiment Flags If Needed.
  const flagKeys = isWeb ? WebExperimentFlagKeys : ExperimentFlagKeys
  flagKeys.map(key => {
    if (!configuredFlags.has(key)) {
      experimentFlags[key] = generateExperimentFlagValue(key)
    }
  })

  experimentFlags[DEFAULT_EXPERIMENTS_ENABLED_FLAG] = true
  experimentFlags[EXPERIMENT_CURRENT_VERSION_FLAG] = EXPERIMENT_CURRENT_VERSION_VALUE

  // Store Updated Experiment Preferences.
  await saveUpdatedExperimentPreferences(experimentFlags, isWeb)
  return experimentFlags
}

const getExperimentPreferences = async (isWeb=false) => {
  if (isWeb) {
    const value = window.localStorage.getItem(EXPERIMENTS_PREFERENCES_KEY)
    const result = {value}
    return result
  } else {
    return await Preferences.get({ key: EXPERIMENTS_PREFERENCES_KEY })
  }
}
 
const saveUpdatedExperimentPreferences = async (updatedPreferencesObject, isWeb=false) => {
  const updatedPreferencesString = JSON.stringify(updatedPreferencesObject)

  if (isWeb) {
    window.localStorage.setItem(EXPERIMENTS_PREFERENCES_KEY, updatedPreferencesString)
  } else {
    await Preferences.set({
      key: EXPERIMENTS_PREFERENCES_KEY,
      value: updatedPreferencesString
    })
  }
}

export const loadUserExperimentFlagsWeb = async () => {
  return await loadUserExperimentFlags(true/*isWeb*/)
}