import React from 'react';
import { CSSTransition } from 'react-transition-group';

const OrganizationPurchaseSuccessModal = ({ 
  show, 
  purchaseDetails, 
  onClose, 
  safeAreaInsets = { top: 0 }, 
  innerHeight = window.innerHeight 
}) => {
  // Extract purchase details with defaults for safety
  const {
    purchasedSeatQuantity = 0,
    planType = 'quarter',
    emailDomain = '',
    checkoutEmailAddress = ''
  } = purchaseDetails || {};

  // Format plan duration for display
  const planDuration = planType === 'annual' ? '1 year' : '3 months';

  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames="redeem-access-code-modal"
      unmountOnExit
    >
      <div
        style={{
          position: 'fixed',
          top: safeAreaInsets.top,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
          backdropFilter: show ? "blur(4px)" : "none",
          WebkitBackdropFilter: show ? "blur(4px)" : "none",
          zIndex: 1000000
        }}
      >
        <div 
          id="organization-purchase-success-modal"
          style={{
            position: 'absolute',
            background: 'white',
            border: "2px black solid",
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            width: "calc(90% - 4px)",
            maxWidth: 600,
            left: 0,
            right: 0,
            margin: "0px auto",
            top: (safeAreaInsets.top) / 4 + (innerHeight / 10),
            maxHeight: Math.min(800, (innerHeight - safeAreaInsets.top) * 0.9),
          }}
          onClick={e => e.stopPropagation()}
        >
          <div style={{color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", height: "100%", color: "#000", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
              <div>
                <div style={{fontSize: "min(6vw, 24px)", marginTop: "4vh", textTransform: "uppercase", fontFamily: "Futura"}}>
                  {"Purchase Complete ⚡"}
                </div>
                
                <div style={{marginTop: "3vh", padding: "min(2vw, 10px) 0px", fontSize: "min(5vw, 22px)", fontFamily: "Futura Book"}}>
                  {`Welcome to NEUROFIT! You've successfully purchased ${purchasedSeatQuantity} app ${purchasedSeatQuantity === 1 ? 'seat' : 'seats'} for ${planDuration}.`}
                </div>
                
                <div style={{padding: "min(2vw, 10px) 0px", fontSize: "min(5vw, 22px)", fontFamily: "Futura Book"}}>
                  <strong>What's Next:</strong>
                </div>
                
                <ul style={{paddingLeft: "25px", fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Book"}}>
                  <li style={{marginBottom: "8px"}}>We've sent a confirmation email with onboarding instructions to {checkoutEmailAddress}</li>
                  <li style={{marginBottom: "8px"}}>To activate access, team members just need to enter your organization's invite code in the app (sent to your checkout email)</li>
                  <li style={{marginBottom: "8px"}}>Our team will reach out to help with any onboarding needs</li>
                </ul>
                
                <div style={{padding: "min(2vw, 10px) 0px", fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Book", marginTop: "10px"}}>
                  If you have any questions, please contact <strong>care@neurofit.app</strong>
                </div>
              </div>
              
              <button
                style={{
                  display: "block", 
                  marginTop: "2vh", 
                  marginBottom: "4vh", 
                  textTransform: "uppercase", 
                  width: "100%", 
                  fontFamily: "Futura", 
                  fontSize: "min(6vw, 25px)", 
                  lineHeight: "min(6vw, 25px)", 
                  color: "#ffffff", 
                  backgroundColor: "#000000", 
                  padding: "12px 15px", 
                  border: "none",
                  cursor: "pointer"
                }}
                onClick={onClose}
              >
                {"Close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default OrganizationPurchaseSuccessModal;