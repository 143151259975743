import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import Helmet from './common/Helmet'

import { history } from '../history'

import {
  baseDomain,
  recordLinkClickGA,
  GlossaryBaseUrl,
  getCategoryFromQueryString,
  HEALTH_EXPERT_COUNT,
  COUNTRY_USER_COUNT,
} from '../utils'

import {
  NeuroFitNamedLogoSvg,
  GlobeSvg,
  BookCheckSvg,
  MedicalBriefcaseSvg,
  OpenBookSvg,
} from '../constants/svgs'

import {
  LocalizedLanguageObjects,
  LanguageCodeEnglish,
  getHreflangTagForLanguageCode,
} from '../languageUtils'

import i18next from 'i18next'

import StaticArticleRecommendation from './common/StaticArticleRecommendation'

const getLocalizedGlossaryPageUrlForLanguageCode = (languageCode, categoryKey=null) => `${GlossaryBaseUrl}/${languageCode}${(!!categoryKey) ? `?c=${categoryKey}` : ""}`

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

class LearnLocalizedSitemapPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      learnMoreRef: React.createRef(),
      headerColor: "transparent",
      dropShadowColor: "transparent",
      show: true,
      showIframe: false,
      selectedCategory: getCategoryFromQueryString(window.location.href),
      searchQueryText: "",
      searchBarFocused: false,
      searchBarHasFocused: false,
    }

    console.log(this.state.selectedCategory)
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      languageCode,
      articlePreviews
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe, selectedCategory, searchQueryText, searchBarFocused, searchBarHasFocused} = this.state

    const articleCategories = [
      "all",
      "neuroscience",
      "nervous system fitness",
      "symptoms",
      // "dysregulation",
      "habits",
      "lifestyle"
    ]

    const articleCategoryObjects = articleCategories.map(c => ({
      key: c,
      value: i18next.t(c)
    }))

    let reCategory = new RegExp(selectedCategory, "i")
    let reSearch = new RegExp(searchQueryText, "i")

    const categoryFilteredArticlePreviews = (selectedCategory !== "")
        ? articlePreviews.filter(preview => reCategory.test(preview.category_tags)) 
        : articlePreviews

    const finalFilteredArticlePreviews = (
            (searchQueryText !== "")
                ? categoryFilteredArticlePreviews.filter(preview => (reSearch.test(preview.title) || reSearch.test(preview.description))) 
                : categoryFilteredArticlePreviews
          )

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={i18next.t('page_title')}
          description={i18next.t('page_description', {hp_count: HEALTH_EXPERT_COUNT, count: articlePreviews.length})}
          href={getLocalizedGlossaryPageUrlForLanguageCode(languageCode, selectedCategory)}
          hrefLangTags={[
            getHreflangTagForLanguageCode(LanguageCodeEnglish, true),
            ...LocalizedLanguageObjects.map((obj) => getHreflangTagForLanguageCode(obj.language_code))
          ]}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              onClick={() => window.location = "/"}
            />
              <a
                href="/nervous-system-glossary"
                rel="noopener noreferrer"
                style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textTransform: "uppercase", textDecoration: "none", color: "#000000"}}
              >
                <GlobeSvg
                  size={"min(5.75vw, 24px)"}
                  color={"#000000"}
                />
              </a>
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw", minHeight: innerHeight}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 5vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <h1 style={{fontSize: "min(8vw, 40px)", margin: "0px", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                    {i18next.t('header_text')}
                  </h1>
                  <h2 style={{margin: "1vh 0px 2vh 0px", padding: "min(3vw, 14px)", boxShadow: "none"}}>
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                      <div style={{display: "inline-block"}}>
                        <div style={{display: "flex", width: "100%", alignItems: "center",justifyContent: "start", color: "#000000", fontSize: "min(5.25vw, 26px)", fontFamily: "Futura"}}>
                          <BookCheckSvg
                            color={"#000000"}
                            size={"min(5.25vw, 26px)"}
                          />
                          <span style={{marginInlineStart: "0.45em"}}>{i18next.t('x_articles', {count: articlePreviews.length})}</span>
                        </div>
                        <div style={{display: "flex", width: "100%", marginTop: "0.5em", alignItems: "center", justifyContent: "start", fontSize: "min(5.25vw, 26px)", color: "#000000", fontFamily: "Futura"}}>
                          <MedicalBriefcaseSvg
                            color={"#000000"}
                            size={"min(5.25vw, 26px"}
                          />
                          <span style={{marginInlineStart: "0.45em"}}>{i18next.t('x_health_experts', {hp_count: HEALTH_EXPERT_COUNT})}</span>
                        </div>
                        <div style={{display: "flex", width: "100%", alignItems: "center", marginTop: "0.5em", justifyContent: "start", color: "#000000", fontSize: "min(5.25vw, 26px)", fontFamily: "Futura"}}>
                          <GlobeSvg
                            color={"#000000"}
                            size={"min(5.25vw, 26px)"}
                          />
                          <span style={{marginInlineStart: "0.45em"}}>{i18next.t('x_countries', {country_count: COUNTRY_USER_COUNT})}</span>
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div style={{width: "100%", marginTop: "4vh", maxWidth: "700px", display: "flex", alignItems: "center", overflow: "visible", backgroundColor: "#FFFFFF", boxShadow: "inset 0px 0px 2px 1.5px #A5A8B0"}}>
                    <CSSTransition
                      in={(searchQueryText === "") && (!searchBarFocused)}
                      timeout={250}
                      classNames="glossary-search-icon-transition"
                    >
                      <svg style={{height: "min(5.75vw, 24px)", width: searchBarHasFocused ? undefined : "min(5.75vw, 24px)", marginInlineEnd: 8, marginInlineStart: searchBarHasFocused ? undefined : 12}} width="449px" height="449px" viewBox="0 0 449 449" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="noun-684728" fill="#777777" fillRule="nonzero"><path d="M439.599,400.401 L305.199,265.301 C324.101,238 336,204.399 336,168 C336,75.602 260.398,-2.84217094e-14 168,-2.84217094e-14 C75.602,-2.84217094e-14 2.84217094e-14,75.602 2.84217094e-14,168 C2.84217094e-14,260.398 75.602,336 168,336 C204.398,336 238,324.102 265.301,305.199 L400.401,440.299 C411.6,451.498 429.1,451.498 440.299,440.299 C450.799,429.1 450.799,410.897 439.59978,400.397 L439.599,400.401 Z M55.999,168.001 C55.999,106.399 106.397,56.001 167.999,56.001 C229.601,56.001 279.999,106.399 279.999,168.001 C279.999,229.603 229.601,280.001 167.999,280.001 C106.397,280.001 55.999,229.603 55.999,168.001 Z" id="Shape"></path></g></g></svg>
                    </CSSTransition>
                    <form action="." style={{width: "100%"}}>
                      <input
                        type="search"
                        id="glossary-search-input"
                        style={{backgroundColor: "transparent"}}
                        value={searchQueryText}
                        autoCapitalize="none"
                        autoCorrect="false"
                        autoComplete="off"
                        spellCheck={false}
                        onFocus={() => {
                          this.setState({searchBarHasFocused: true, searchBarFocused: true})
                        }}
                        onBlur={() => {
                          this.setState({searchBarFocused: false})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter') {
                            document.getElementById("glossary-search-input").blur()
                          }
                        }}
                        onChange={(e) => {
                          const updatedQuery = e.target.value.slice(0, 100)
                          this.setState({searchQueryText: updatedQuery})
                        }}
                        onPaste={(e) => {
                          const updatedQuery = e.target.value.slice(0, 100)
                          this.setState({searchQueryText: updatedQuery})
                        }}
                        placeholder={i18next.t('subheader_text_instructions')}
                        className={"glossary-search-input"}
                      />
                    </form>
                  </div>
                  <div style={{minWidth: "100%",  marginTop: "0.7vh"}}>
                    {articleCategoryObjects.map(view => {
                      const isSelected = (view.key === selectedCategory) || ((view.key === "all") && (!selectedCategory)) 
                      const categoryUrl = getLocalizedGlossaryPageUrlForLanguageCode(languageCode, ((view.key !== "all") && view.key))
                      return (
                        <a
                          href={categoryUrl}
                          key={view.key}
                          style={{padding: "3px 6px", textTransform: "uppercase", textDecoration: "none", margin: "15px 18px 0px 0px", display: "inline-block", cursor: "pointer", color: "#000000", backgroundColor: isSelected ? "#FFFFFF" : "#EDEEF2", boxShadow: isSelected ? "0px 0px 0.5px 1.5px #888888" : "0px 0px 0px 1.5px #A5A8B0", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(3.5vw, 16px)"}}
                        >
                          {view.value}
                        </a>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div style={{color: "black", marginTop: "6vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{marginBottom: "1.5vh", fontSize: "min(5.75vw, 24px)", textTransform: "uppercase", textAlign: "start", fontFamily: "Futura Medium"}}>
                    {`${i18next.t('results')}: ${finalFilteredArticlePreviews.length}`}
                  </div>
                  <div style={{marginBottom: "8vh"}}>
                    {finalFilteredArticlePreviews.map(rec => (
                      <StaticArticleRecommendation
                        articleData={rec}
                        languageCode={languageCode}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {i18next.t('copyright_neurofit')}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {i18next.t('all_rights_reserved')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LearnLocalizedSitemapPage;