import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  resetLoginPage,
} from '../actions/loginPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
} from '../utils'

import LearnLocalizedSitemapPage from '../components/LearnLocalizedSitemapPage';

import AppLoadingPageContainer from './AppLoadingPageContainer'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  SetGlobalI18N,
} from '../languageUtils'

import agent from '../agent'

import i18next from 'i18next'

// const getThumbnailUrl = slug => `https://cdn.neurofit.app/learn/images/${slug}/thumbnail.jpg`
const getHiResThumbnailUrl = slug => `https://cdn.neurofit.app/learn/images/${slug}/1x1.jpg`

class LearnLocalizedSitemapPageContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
        articlePreviews: [],
        fetchGlossaryI18nDataResultState: REQUEST_UNSTARTED
    }
  }

  async fetchGlossaryI18nData() {
    const {username} = this.state

    this.setState({...this.state, fetchGlossaryI18nDataResultState: REQUEST_FETCHING})
    try {
      const language_code = this.props.match.params.languageCode
      const glossaryObject = await agent.NeuroFitBackend.getGlossaryI18NForLanguageCode({language_code})
      SetGlobalI18N(glossaryObject.i18n, language_code)
      const formattedArticlePreviews = glossaryObject.article_previews.map(p => ({...p, thumbnail_image_url: getHiResThumbnailUrl(p.article_slug)}))
      this.setState({...this.state, fetchGlossaryI18nDataResultState: REQUEST_SUCCESS, articlePreviews: formattedArticlePreviews})
    } catch (err) {
      console.log(`ERROR: ${JSON.stringify(err)}`)
      this.setState({...this.state, fetchGlossaryI18nDataResultState: REQUEST_ERROR})
    }
  }

  async componentWillMount(props) {
    await this.fetchGlossaryI18nData()
  }

  componentDidMount() {
    hideLoadingScreen()
  }
  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {

    const {articlePreviews, fetchGlossaryI18nDataResultState} = this.state

    const isGlossaryDataLoaded = (fetchGlossaryI18nDataResultState === REQUEST_SUCCESS)

    return (isGlossaryDataLoaded ? (
      <LearnLocalizedSitemapPage
        // State
        languageCode={this.props.match.params.languageCode}
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOS={this.props.isIOSDevice}
        isAndroid={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        countryCode={this.props.countryCode}
        articlePreviews={articlePreviews}
        // Actions
      />
    ) : (
      <AppLoadingPageContainer
        appLoadResultState={fetchGlossaryI18nDataResultState}
        appLoadErrorMessage={"Error: Glossary Page failed to load."}
        appReloadButtonText={"Retry"}
        onReloadApp={async () => await this.fetchGlossaryI18nData()}
        props={this.props}
      />
    ))
  }
}

LearnLocalizedSitemapPageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  countryCode: "US",
});

export default connect(mapStateToProps)(LearnLocalizedSitemapPageContainer);
