import React from 'react';

import DownloadButtonsSection from './DownloadButtonsSection'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAffiliateSignupLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  NeuroFitInstagramLink,
  NeuroFitLinkedInLink,
  ProductionBaseUrl,
  ProductionWorkshopsUrl,
  ProductionCertificationUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordLinkClickGA,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  initializePixelIfAvailable,
  NeuroFitSkoolCommunityLink,
  isProduction,
  onceEventListener,
  BlogSomaticExercisesWeightLossUrl,
  BlogSomaticExercisesSleepUrl,
  BlogSomaticExercisesTraumaUrl,
  BlogDorsalVagalRegulationUrl,
  BlogNervousSystemRegulationTimeUrl,
  BlogSomaticExercisesAngerUrl,
  BlogNervousSystemCoachUrl,
  BlogSomaticCoachUrl,
  LearnOverstimulatedUrl,
  LearnSomaticYogaUrl,
  LearnFunctionalFreezeUrl,
  LearnCoreSleepUrl,
  LearnBrainMuscleUrl,
  LearnIGAuditBridgeUrl,
  ProductionLegalTermsOfServiceUrl,
  ProductionLegalPrivacyPolicyUrl,
  ProductionGlossaryEnglishUrl,
  isIOSDevice,
  isAndroidDevice,
} from '../../utils'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_AFFILIATE_SIGNUP_LINK,
} from '../../constants/gaEventLabels'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
  NeuroFitNamedLogoLinkSvg,
  VerifiedSvg,
  InstagramSvg,
  LinkedInSvg,
  OpenBookSvg,
} from '../../constants/svgs'

const FooterSiteLink = ({url, title}) => (
  <div style={{margin: "1vh 0px"}}>
    <a
      href={url}
      target="_blank"
      style={{fontFamily: "Futura Book", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
    >
      {title}
    </a>
  </div>
)

const getArticleUrl = key => `https://neurofit.app/learn/en/${key}/`

const learnArticleSiteLinks = [
  {
    url: getArticleUrl("what-are-somatic-exercises"),
    title: "What Are Somatic Exercises?"
  },
  {
    url: getArticleUrl("what-are-somatic-workouts"),
    title: "What Are Somatic Workouts?"
  },
  {
    url:  getArticleUrl("what-is-somatic-yoga"),
    title: "What Is Somatic Yoga?"
  },
  {
    url: getArticleUrl("what-is-a-dysregulated-nervous-system"),
    title: "What Is A Dysregulated Nervous System?"
  },
  {
    url: getArticleUrl("window-of-tolerance"),
    title: "The Window of Tolerance"
  },
  {
    url: getArticleUrl("top-9-dysregulated-nervous-system-symptoms"),
    title: "Top Dysregulated Nervous System Symptoms"
  },
  {
    url: getArticleUrl("how-to-regulate-a-dysregulated-nervous-system"),
    title: "How To Regulate Your Nervous System"
  },
  {
    url: getArticleUrl("5-somatic-exercises-for-anxiety"),
    title: "Somatic Exercises For Anxiety"
  },
  {
    url: getArticleUrl("5-somatic-exercises-for-anger"),
    title: "Somatic Exercises For Anger"
  },
  {
    url: getArticleUrl("5-somatic-exercises-to-relieve-stress-in-minutes"),
    title: "Somatic Exercises To Relieve Stress"
  },
  {
    url: getArticleUrl("what-is-cortisol-face"),
    title: "What Is Cortisol Face?"
  },
  {
    url: getArticleUrl("reticular-formation"),
    title: "The Reticular Formation"
  },
  {
    url: LearnFunctionalFreezeUrl,
    title: "Functional Freeze / Shutdown"
  },
  {
    url: LearnCoreSleepUrl,
    title: "What is Core Sleep?"
  },
  {
    url: LearnOverstimulatedUrl,
    title: "Overstimulated? Here's What To Do"
  },
  {
    url: LearnBrainMuscleUrl,
    title: "Is the Brain a Muscle?"
  },
  {
    url: LearnIGAuditBridgeUrl,
    title: "IG Audit To NEUROFIT: Solving Online Stress"
  },
  {
    url: BlogSomaticExercisesWeightLossUrl,
    title: "Somatic Exercises For Weight Loss"
  },
  {
    url: BlogSomaticExercisesTraumaUrl,
    title: "Somatic Exercises To Relieve Trauma"
  }
]


const WebsiteFooterSection = ({
  safeAreaInsets,
  onOpenAppQrCodeModal,
  showFoundersSection,
  showWebAppCheckoutSection,
  showRecentReleasesSection
}) => {

  const currentYear = getCurrentYear()
  const isIOS = isIOSDevice()
  const isAndroid = isAndroidDevice()
  const appDownloadUrl = isIOS ? NeuroFitAppleAppStoreLink : (isAndroid ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

  return (
    <div style={{textAlign: "start", width: "100vw", backgroundColor: "#000000", paddingTop: "calc(60px + 5vh)", borderTop: "2px #000000 solid"}}>
      <div>
        <div style={{color: "black", width: "100%", display: "flex", color: "#FFFFFF", justifyContent: "center", alignItems: "top"}}>
          <div style={{width: "85%", maxWidth: 700}}>
            <div style={{width: "100%", display: "flex", color: "#FFFFFF", justifyContent: "left", alignItems: "top"}}>
              <NeuroFitNamedLogoLinkSvg
                logoSize={"min(5vw, 20px)"}
                color={"#FFFFFF"}
                href="/"
              />
              <img src={"/images/neurofit-app-icon-1x.webp"} style={{marginInlineStart: 30, height: 100, width: 100, filter: `drop-shadow(0px 0px 3px #A2A5B0)`}} />
            </div>
            <div style={{fontFamily: "Futura Book", display: "block", margin: "3vh 0px", fontSize: "min(4.5vw, 18px)", color: "#CFD2DD"}}>
              {"Balance your nervous system, reduce stress, and feel your best with NEUROFIT."}
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "left", alignItems: "center"}}>
              <a
                itemprop="sameAs"
                href={NeuroFitInstagramLink}
                rel="me noreferrer noopener"
                target="_blank"
                style={{backgroundColor: "#1F1F1F", cursor: "pointer", borderRadius: "5px", height: "min(9vw, 45px)", width: "min(9vw, 45px)", display: "flex", justifyContent: "center", alignItems: "center"}}
              >
                <InstagramSvg
                  color={"#CFD2DD"}
                  size={"min(5vw, 22px)"}
                />
              </a>
              <a
                itemprop="sameAs"
                href={NeuroFitLinkedInLink}
                rel="me noreferrer noopener"
                target="_blank"
                style={{marginInlineStart: "min(6vw, 26px)", backgroundColor: "#1F1F1F", cursor: "pointer", borderRadius: "5px", height: "min(9vw, 45px)", width: "min(9vw, 45px)", display: "flex", justifyContent: "center", alignItems: "center"}}
              >
                <LinkedInSvg
                  color={"#CFD2DD"}
                  size={"min(5vw, 22px)"}
                />
              </a>
            </div>
            <DownloadButtonsSection
              isIOSDevice={isIOS}
              isAndroidDevice={isAndroid}
              whiteButtonBorder
              onShowQrCodeModal={() => onOpenAppQrCodeModal()}
              onDownloadButtonClick={() => {}}
            />
            <div style={{fontSize: "min(6vw, 30px)", color: "#FFFFFF", textAlign: "center", fontFamily: "Futura Medium", margin: "5vh 0px", textTransform: "uppercase"}}>
              {"Nervous System Fitness"}<sup>{"®"}</sup>
            </div>
            <div style={{fontFamily: "Futura", textTransform: "uppercase", marginTop: "5vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
              {"Our Products"}
            </div>
            <a
              href={"/certification"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Nervous System+Somatics Certification"}
            </a>
            <span
              onClick={() => {
                if (isIOS || isAndroid) {
                  recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                } else {
                  onOpenAppQrCodeModal()
                }
              }}
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"NEUROFIT Mobile App"}
            </span>
            <a
              href={"/teams"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"NEUROFIT For Teams/Organizations"}
            </a>
            <span
              onClick={() => {
                recordLinkClickAndRedirect(GA_LABEL_CLICKED_AFFILIATE_SIGNUP_LINK, NeuroFitAffiliateSignupLink)
              }}
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Become A NEUROFIT Affiliate"}
            </span>

            <div style={{fontFamily: "Futura", textTransform: "uppercase", marginTop: "6vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
              {"Tools For Practitioners"}
            </div>
            <a
              href={ProductionGlossaryEnglishUrl}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Nervous System + Somatics Glossary"}
            </a>
            <a
              href={"/balance"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Personalized Somatic Exercise Tool"}
            </a>
            <a
              href={"/quiz"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Nervous System Dysregulation Quiz"}
            </a>

            <div style={{fontFamily: "Futura", textTransform: "uppercase", textTransform: "uppercase", fontSize: "min(5.5vw, 22px)", marginTop: "6vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
              {"Top Glossary + Blog Articles"}
            </div>
            {learnArticleSiteLinks.map(({url, title}) => (
              <FooterSiteLink
                id={url}
                url={url}
                title={title}
              />
            ))}
            {showFoundersSection && (
              <div>
                <div style={{fontFamily: "Futura", textTransform: "uppercase", marginTop: "5vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                  {"Founders"}
                </div>
                <a
                  href={"/author/loren-hogue"}
                  target="_blank"
                  style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                >
                  {"Loren Hogue"}
                </a>
                <a
                  href={"/author/andrew-hogue"}
                  target="_blank"
                  style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                >
                  {"Andrew Hogue"}
                </a>
              </div>
            )}
            <div style={{fontFamily: "Futura", textTransform: "uppercase", marginTop: "5vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
              {"Business Details"}
            </div>
            <a
              href={"/about"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"About Us"}
            </a>
            <a
              href={"/product-demo"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"NEUROFIT App Demo"}
            </a>
            <a
              href={"/wall-of-love"}
              target="_blank"
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Wall Of Love 🤍"}
            </a>
            {showWebAppCheckoutSection && (
              <a
                href={"/app-checkout"}
                target="_blank"
                style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
              >
                {"Web-Based Membership"}
              </a>
            )}
            <span
              onClick={() => {
                window.open(ProductionLegalTermsOfServiceUrl, "_blank", "noopener nofollow")
              }}
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Terms of Service"}
            </span>
            <span
              onClick={() => {
                window.open(ProductionLegalPrivacyPolicyUrl, "_blank", "noopener nofollow")
              }}
              style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
            >
              {"Privacy Policy"}
            </span>
            {showRecentReleasesSection && (
              <a
                href={"/recent-releases"}
                target="_blank"
                style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
              >
                {"Recent Releases"}
              </a>
            )}
            <div style={{marginTop: "2vh", color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`Xama Technologies, Inc. (dba NEUROFIT)`}
            </div>
            <div style={{color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`651 North Broad Street, Suite 206`}
            </div>
            <div style={{color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`Middletown, DE, USA 19709`}
            </div>
            <a href="mailto:care@neurofit.app" style={{marginTop: "2vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`care@neurofit.app`}
            </a>
            <a href="mailto:press@neurofit.app" style={{marginTop: "1vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`press@neurofit.app`}
            </a>
            <a href="mailto:contact@neurofit.app" style={{marginTop: "1vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
              {`contact@neurofit.app`}
            </a>
            <div style={{paddingBottom: 15 + safeAreaInsets.bottom, paddingTop: "10vh", backgroundColor: "transparent", fontSize: "min(4vw, 18px)", fontFamily: "Futura", display: "flex", "justifyContent": "left"}}>
              <span style={{color: "#FFFFFF", lineHeight: "min(3.5vw, 16px)"}}>
                {`© NEUROFIT ${currentYear}`}
              </span>
              <span style={{display: "inline-block", fontFamily: "Futura Medium", color: "#FFFFFF", margin: "0px 0.2em", lineHeight: "min(5.5vw, 22px)", fontSize: "min(4.5vw, 18px)"}}>
                {"•"}
              </span>
              <span style={{color: "#FFFFFF", textTransform: "uppercase", lineHeight: "min(4vw, 18px)"}}>
                {"All Rights Reserved"}
              </span>
            </div>
            <div style={{fontFamily: "Futura Book", display: "block", marginTop: "4vh", fontStyle: "italic", fontSize: 15, color: "#a5a8b0"}}>
              {"The NEUROFIT products, programs and services are intended to help maintain and encourage a healthy lifestyle, and are not to be used for the diagnosis, cure, management, prevention, or treatment of any disease or condition."}
            </div>
            <div style={{fontFamily: "Futura Book", display: "block", fontStyle: "italic", margin: "1vh 0px 4vh 0px", fontSize: 15, color: "#a5a8b0"}}>
              {"As always, speak with your trusted health professional before making any health-related decisions or using any health-related products or services."}
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteFooterSection