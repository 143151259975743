import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import TrainerCertificationDetails from './common/TrainerCertificationDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoLinkSvg,
  OvalSpinnerSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK
} from '../constants/gaEventLabels'

import agent from '../agent'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationUrl,
  ProductionCourseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  getAffiliateCodeFromQueryString,
  getDeeplinkedPageSectionKeyFromQueryString,
  getDeeplinkedTestimonialCategoryFromQueryString,
  getDeeplinkedCheckoutPlanFromQueryString,
  identifyCertificationAnalyticsWebUser,
  configureAnalyticsUserProperties,
  delay,
} from '../utils'

import Helmet from './common/Helmet'

class CertificationPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#EDEEF2",
      topPageScrollProgress: 0,
      hasLinkedAffiliateCode: (!!(getAffiliateCodeFromQueryString(window.location.href))),
      certificationCodeDetailsRequest: {state: REQUEST_UNSTARTED},
      deeplinkedPageSectionKey: getDeeplinkedPageSectionKeyFromQueryString(),
      deeplinkedTestimonialCategoryKey: getDeeplinkedTestimonialCategoryFromQueryString(),
      deeplinkedCheckoutPlanKey: getDeeplinkedCheckoutPlanFromQueryString()
    }
  }

  async componentWillMount() {
    try {
      const unique_code = getAffiliateCodeFromQueryString(window.location.href)
      if (!!unique_code) {
        this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_FETCHING}})
        const codeDetailsRequestResult = await agent.NeuroFitBackend.retrieveCertificationUniqueCodeDetails({unique_code})
        this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_SUCCESS, result: codeDetailsRequestResult}})
        await identifyCertificationAnalyticsWebUser({uuid: unique_code})
        await configureAnalyticsUserProperties({experimentFlags: {experiment__cert_offer_unique_code_expiration_days: codeDetailsRequestResult.expiration_days || 5}})
      }

    } catch (err) {
      this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_ERROR}})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage
    } = this.props

    const {headerBorderColor, deeplinkedPageSectionKey, deeplinkedTestimonialCategoryKey, deeplinkedCheckoutPlanKey, showSpinner, topPageScrollProgress, hasLinkedAffiliateCode, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, certificationCodeDetailsRequest} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={isNasmPage ? "Nervous System Course For Fitness Trainers & Gym Owners | NEUROFIT" : "Somatic + Nervous System Coach Certification | NEUROFIT"}
          description={isNasmPage ? "NEUROFIT Nervous System Course. Trusted by hundreds of embodied health experts, backed by data." : "Somatic Coach Certification + Nervous System Coach Certification for health professionals, covering nervous system regulation, somatic exercises, and trauma-informed witnessing."}
          href={isNasmPage ? ProductionCourseUrl : ProductionCertificationUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoLinkSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={"#FFFFFF"}
              href={hasLinkedAffiliateCode ? "#" : "/"}
            />
          </div>
          <TrainerCertificationDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            isNative={false}
            isNasmPage={isNasmPage}
            deeplinkedPageSectionKey={deeplinkedPageSectionKey}
            deeplinkedTestimonialCategoryKey={deeplinkedTestimonialCategoryKey}
            deeplinkedCheckoutPlanKey={deeplinkedCheckoutPlanKey}
            onContainerScroll={updatedState => {
              this.setState({...updatedState})
            }}
            certificationCodeDetailsRequest={certificationCodeDetailsRequest}
          />
        </div>
      </div>
    )
  }
}

export default CertificationPage;