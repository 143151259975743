import React, {Suspense} from 'react'

import PendingResultsPanel from './PendingResultsPanel'

import {
  NeuroFitNamedLogoSvg,
  NeuroFitLogoSvg,
} from '../../constants/svgs'

import {
  isRtlLanguage,
} from '../../languageUtils'

import range from 'lodash/range'

import i18next from 'i18next'


import loadable from '@loadable/component'
const ChartistLineGraph = loadable(() => import('./ChartistLineGraph'))
// const chartistPluginAxisTitle = loadable(() => import('chartist-plugin-axistitle'))
// import ChartistLineGraph from './ChartistLineGraph';
// import chartistPluginAxisTitle from 'chartist-plugin-axistitle'

class LineGraph extends React.Component {

  constructor(props) {
    super(props)
  }

  preload() {
    ChartistLineGraph.preload()
  }

  render() {
    const {
      dataIsAvailable,
      title,
      data,
      labelInterpolationFnc,
      highYValue,
      pendingTitle,
      pendingText,
      xAxisTitle,
      hideLogo,
      marginTop
    } = this.props

    // TODO: Finalize.
    const isRtl = isRtlLanguage()
    const formattedData = isRtl ? ({labels: data.labels.toReversed(), series: data.series.map(s => s.toReversed())}) : data

    return (
      <div>
        {dataIsAvailable ? (
          <div>
            <div style={{backgroundColor: "#FFFFFF", padding: "min(4vw, 20px)", marginTop: marginTop || "max(3vw, 16px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "inline-block", fontFamily: "Futura", textTransform: "uppercase", fontWeight: "normal", fontSize: "min(5vw, 22px)", lineHeight: "min(5vw, 22px)"}}>
                  {title}
                </div>
              </div>
              <Suspense fallback={
                <div className={"article-loading-background-animation"} style={{height: 190, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <NeuroFitLogoSvg
                    size={"min(8vw, 40px)"}
                    color={"#CFD2DD"}
                    lineColor={"#EDEEF2"}
                  />
                </div>
              }>
                <div>
                  <ChartistLineGraph
                    data={formattedData}
                    options={{
                      height: '190px',
                      fullWidth: true,
                      chartPadding: {
                        top: 20, 
                        bottom: !!(xAxisTitle) ? 10 : 2,
                        left: 0,
                        right: 20
                      },
                      labelDirection: "explode",
                      axisY: {
                        labelInterpolationFnc,
                        low: 0,
                        high: highYValue,
                        referenceValue: 20,
                        onlyInteger: true,
                        offset: 50
                      },
                      axisX: {
                        // showGrid: false,
                        name: xAxisTitle,
                        showLabel: !!(xAxisTitle) && (!isRtl),
                        offset: (!!(xAxisTitle) && (!isRtl)) ? 10 : 0,
                      }
                    }}
                  />
                  {!hideLogo && (
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center", marginTop: -40, paddingInlineEnd:30}}>
                      <NeuroFitNamedLogoSvg
                        logoSize={"min(4vw, 18px)"}
                        color={"#D5D6D9"}
                      />
                    </div>
                  )}
                </div>
              </Suspense>
              {!!(xAxisTitle) && (
                <div style={{textAlign: "center", fontFamily: "Futura", zIndex: -1, fontWeight: "normal", fontSize: "min(4vw, 18px)"}}>
                  {xAxisTitle}
                </div>
              )}
            </div>
          </div>
        ) : (
          <PendingResultsPanel
            title={pendingTitle}
            color={"#747a8c"}
            pendingText={pendingText}
          />
        )}
      </div>
    )
  }
}

export { ChartistLineGraph }; 
export default LineGraph;