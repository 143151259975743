import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
  isAndroidDevice,
  isIOSDevice,
  preloadImagePaths,
  NeuroFitWorkplaceBurnoutRecoveryPlanLink,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
  CalendlyTeamAppAccessLink,
  getCertOptInNameFromQueryString,
  getCertOptInEmailAddressFromQueryString,
  getCertOptInIsPrefilledFromQueryString,
  recordLinkClickAndRedirect,
  getCurrentYear,
  getPixelParams,
  getCampaignCodeFromQueryString,
  delay,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Haptics, ImpactStyle, NotificationType } from '../../hapticUtils';
import { range } from 'lodash-es';
import StarRatings from 'react-star-ratings'

import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import PieChartInsightSection from './PieChartInsightSection'
import AppReviewPanel from './AppReviewPanel'
import LineGraph from './LineGraph'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import SendRequestButton from './SendRequestButton'
import ConfirmSection from './ConfirmSection'
import DownloadButtonsSection from './DownloadButtonsSection'
import InlineTrainerTestimonial from './InlineTrainerTestimonial'
import TeamsAppPurchaseSuccessModal from '../modals/TeamsAppPurchaseSuccessModal'


import agent from '../../agent'

import {
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_TEAMS_CLICKED_REGISTER_FOR_TEAMS,
  GA_LABEL_TEAMS_CLICKED_REPORT
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import loadable from '@loadable/component'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const TeamAppAccessStripeCheckoutForm = loadable(() => import('./TeamAppAccessStripeCheckoutForm'))


class TeamOptInFormDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#FFFFFF",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: "",
      lastName: "",
      emailAddress: "",
      organizationName: "",
      professionalTitle: "",
      teamSize: "",
      optionCertificationCourse: false,
      optionExecutiveCoaching: false,
      optionTeamAppAccess: true,
      optionLiveTeamWorkshops: true,
      purchaseDetails: {},
      isAndroid: isAndroidDevice(),
      isIOS: isIOSDevice(),
      optInResultState: REQUEST_UNSTARTED,
      showPaymentSuccessModal: false
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const {hasScrolled} = this.state

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(4px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const heroBackdropFilter = `blur(4px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#FFFFFF", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    const {isNative} = this.props

    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable(!isNative/*initTikTok*/)

    const innerWidth = document.body.clientWidth || window.innerWidth
    const isMobile = innerWidth <= MinDesktopWidthPixels

    await preloadImagePaths([isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH])
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isModal,
      onClickCertificationButton,
    } = this.props

    const {show, purchaseDetails, professionalTitle, showPaymentSuccessModal, hasScrolled, isAndroid, isIOS, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const currentYear = getCurrentYear()
    
    return (
      <div 
        id={"teams-opt-in-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={{...containerStyle, height: isModal ? undefined : "100%"}}
      >
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid", paddingTop: `calc(60px + 3vh)`}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              {isMobile ? (
                <h1 style={{fontSize: "min(8vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                  <span>{"Burnout Costs"}</span>
                  <br/>
                  <span>{"Up To $21K/Year --"}</span>
                  <br/>
                  <span>{"Per Employee."}</span>
                  <br/>
                  <span>{"Let's Fix That."}</span>
                </h1>
              ) : (
                <h1 style={{fontSize: "min(8vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                  <span>{"Burnout Costs Up To $21K/Year"}</span>
                  <br/>
                  <span>{"Per Employee - Let's Fix That."}</span>
                </h1>
              )}
              <h2 style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Book", marginTop: "2vh", marginInlineEnd: "0.3em"}}>
                {"Help your team perform at their peak with a "}
                <span style={{fontFamily: "Futura"}}>{`high-performance wellness app + live workshops.`}</span>
              </h2>
              <div style={{display: "flex", width: "100%", margin: "5vh 0px 1vh 0px", justifyContent: "start"}}>
                <div style={{fontSize: "min(3.5vw, 15px)", width: "min(700px, 85vw)", textTransform: "uppercase", opacity: 0.6, textAlign: "start", fontFamily: "Futura"}}>
                  {"Used by High Performers At"}
                </div>
              </div>
              <div style={{display: "flex", width: "100%", alignItems: "center", height: `calc(min(700px, 85vw) / ${isMobile ? 1.3821 : 2.7733})`, justifyContent: "space-around"}}>
                <img 
                  onLoad={() => {
                    this.setState({isHeroImageLoaded: true})
                  }}
                  src={isMobile ? "/images/media/brand-logos-12-mobile-black.webp?v=0" : "/images/media/brand-logos-12-4x3-desktop-black.webp?v=0"}
                  fetchpriority="high"
                  style={{width: "100%", maxWidth: 700, minHeight: `calc(min(700px, 100%) / ${isMobile ? 1.3821 : 2.7733})`, opacity: isHeroImageLoaded ? 0.5 : 0.0, transition: "opacity 150ms linear"}}
                  alt="NEUROFIT is used by high-performers at Deloitte, Virgin, Tesla, Lego, SoftBank, FedEx and more."
                />
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center", marginTop: "5vh", pointerEvents: "none"}}>
                  <svg onClick={() => this.productInfoSection.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{width: "100vw", paddingTop: "60px", backgroundColor: "#FFFFFF"}} ref={elem => this.productInfoSection = elem}>
          <div style={{textAlign: "start", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
            <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
              <img fetchpriority="high" src="/images/well-and-good-laurel-white.webp?v=2" style={{width: "min(75%, 400px)", height: "calc(min(75%, 400px) * 120 / 501)"}} alt="NEUROFIT eased my stress in ways meditation never has -Well and Good" />
            </div>
            <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
              <img fetchpriority="high" src="/images/shape-groundbreaking-recovery-innovations-white.webp?v=3" style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)", height: "calc(min(calc(75% * 0.908), calc(400px * 0.908) * 152 / 501)"}} style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)"}} alt="NEUROFIT is a 2023 SHAPE Magazine Groundbreaking Recovery Innovation." />
            </div>
            <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
              <img fetchpriority="high" src="/images/vogue-instant-nervous-system-reset-white.webp?v=2" style={{width: "75%", maxWidth: 400, height: "calc(min(75%, 400px) * 140 / 501)"}} alt="NEUROFIT instantly resets your nervous system -Vogue" />
            </div>
            <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
              <img fetchpriority="high" src="/images/business-insider-laurel-white.webp?v=0" style={{width: "75%", maxWidth: 400, height: "calc(min(75%, 400px) * 118 / 501)"}} alt="Evidence-based somatic movements proven to reduce stress. -Business Insider" />
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}} ref={elem => this.learnMoreRef = elem}>
          <div style={{height: "100%"}}>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "90%", maxWidth: 700}}>
                <div style={{display: "inline-block", fontSize: "min(8vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"Peak Performance, No Burnout Needed."}
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                  <img src={`/images/hero-phone-balance-${isMobile ? "mobile" : "desktop"}.webp?v=0`} fetchpriority="high" style={{height: "50vh", zIndex: 1, margin: "3vh 0px"}} />
                  <img src={`/images/hero-phone-insights-${isMobile ? "mobile" : "desktop"}.webp?v=0`} fetchpriority="high" style={{height: "calc(50vh * 0.896)", zIndex: 0, margin: "calc(3vh + calc(50vh * 0.052)) 0px 3vh 0px"}} />
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Book"}}>
                  <span>{`With organizations, burnout is both `}</span>
                  <span style={{fontFamily: "Futura"}}>{`silent and expensive`}</span>
                  <span>{`. There are no obvious signs - `}</span>
                  <span style={{fontFamily: "Futura"}}>{`just lost productivity`}</span>
                  <span>{`.`}</span>
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Book"}}>
                  <span>{` NEUROFIT is designed to solve burnout fast. On average, active app users report `}</span>
                  <span style={{fontFamily: "Futura"}}>{`54% less stress after 1 week.`}</span>
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Book"}}>
                  <span style={{fontFamily: "Futura"}}>{`Hundreds of therapists, doctors, health coaches, and more`}</span>
                  <span>{` use our nervous system method with clients.`}</span>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "5vh", pointerEvents: "none"}}>
                  <img src={isMobile ? "/images/media/media-logos-9-mobile-black.webp?v=0" : "/images/media/media-logos-9-desktop-black.webp?v=0"} fetchpriority="high" style={{width: "85%", maxWidth: 500, height: "calc(min(500px, 85%) / 2.9791)", opacity: 0.5}} alt="NEUROFIT has been featured in SHAPE, Well and Good, Women's Health, Men's Health and more." />
                </div>
                <div style={{textAlign: "center", margin: "5vh auto 2vh auto", opacity: 1.0}}>
                  <div>
                    <StarRatings
                      disabled
                      rating={4.8}
                      starRatedColor={"#000000"}
                      starEmptyColor={"#CFD2DD"}
                      starDimension={"min(8vw, 40px)"}
                      starSpacing={"min(2vw, 10px)"}
                      numberOfStars={5}
                    />
                  </div>
                  <div style={{fontSize: "min(5vw, 26px)", textAlign: "center", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"4.8/5 App Store Rating"}
                  </div>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                    <VerifiedSvg
                      color={"#a5a8b0"}
                      size={"min(4.5vw, 18px)"}
                    />
                    <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                      {"100,000+ Users"}
                    </div>
                  </div>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                    <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                      {"IOS + ANDROID"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{width: "100%", backgroundColor: "#EDEEF2", padding: "calc(60px + 5vh) 0px", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", backgroundColor: "#EDEEF2", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8vw, 40px)", textTransform: "uppercase", textAlign: "start"}}>
                <div>{"Support Your Organization Now"}</div>
              </div>
            </div>
          </div>
          <div style={{minWidth: "100%"}}>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Book", marginTop: "2vh", marginInlineEnd: "0.3em"}}>
                  <span>{"Get instant team app access below, or "}</span>
                  <a
                    href={CalendlyTeamAppAccessLink}
                    target={"_blank"}
                    rel="nofollow noopener noreferrer"
                    style={{fontFamily: "Futura", color: "#000000"}}
                  >
                    {"book"}
                  </a>
                  <span>{" a strategy call for tailored team workshops."}</span>
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", marginTop: `6vh`, justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                  {"Buy Team App Access"}
                </div>
                <TeamAppAccessStripeCheckoutForm
                  isNative={isNative}
                  onPaymentSuccess={(purchaseDetails) => this.setState({purchaseDetails, showPaymentSuccessModal: true, paymentSuccess: true})}
                  onShowErrorMessage={() => this.setState({showPaymentSuccessModal: true})}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{padding: 15, paddingTop: 30, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
          <div style={{display: "inline-block"}}>
            <span style={{color: "#c1c4d2"}}>
              {`© NEUROFIT ${currentYear}`}
            </span>
            <span style={{display: "inline-block", margin: "0px 0.2em", fontFamily: "Futura Medium", fontSize: "min(4.5vw, 18px)"}}>
              {"•"}
            </span>
            <span style={{color: "#c1c4d2"}}>
              {"ALL RIGHTS RESERVED"}
            </span>
          </div>
        </div>
        <TeamsAppPurchaseSuccessModal
          show={showPaymentSuccessModal}
          purchaseDetails={purchaseDetails}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          onClose={() => this.setState({showPaymentSuccessModal: false})}
        />
      </div>
    )
  }
}

export default TeamOptInFormDetails