import React from 'react'

import { Helmet } from 'react-helmet';

const NeuroFitHelmet = ({
  title,
  description,
  href,
  hrefLangTags,
  useNoindex
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={href} />
    {useNoindex && <meta content="noindex" name="robots" />}
    {(hrefLangTags && hrefLangTags.length > 0) && hrefLangTags}
  </Helmet>
)

export default NeuroFitHelmet