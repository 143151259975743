import { Haptics as CapacitorHaptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

// Create a wrapped Haptics object
const WrappedHaptics = (() => {
  // Private enabled state
  let enabled = true;

  // Define the wrapper object
  const haptics = {
    // Method to toggle enabled state
    setEnabled(bool) {
      enabled = bool;
    },
    // Getter for enabled state (optional, if you need it)
    isEnabled() {
      return enabled;
    }
  };

  // List of methods to wrap
  const methodsToWrap = [
    'impact',
    'notification',
    'vibrate',
    'selectionStart',
    'selectionChanged',
    'selectionEnd'
  ];

  // Wrap each method
  methodsToWrap.forEach(methodName => {
    if (typeof CapacitorHaptics[methodName] === 'function') {
      haptics[methodName] = function (...args) {
        if (enabled) {
          return CapacitorHaptics[methodName](...args);
        }
        // Return a resolved promise for async methods or undefined for sync
        return CapacitorHaptics[methodName].length > 0
          ? Promise.resolve()
          : undefined;
      };
    }
  });

  return haptics;
})();

// Export everything your app needs
export const Haptics = WrappedHaptics;
export { ImpactStyle, NotificationType } from '@capacitor/haptics';